import {mapState, mapMutations} from "vuex";
import mobile from "@/common/mobile-input"

export default {
    data() {
        return {
            model: null,
            single: {},
            single_copy:{},
            header: [],
            fields: {},
            extra_filters: {},
            edit_mode: false,
            view_mode: false,
            use_cache: false,
            areas: [],
            page: {
                total: 0,
                display: 1,
                current: 0,
                count: 0,
                size: 20
            },
            search: {
                search: '',
                filters: [],
                page: 1,
                sort: {},
                date_rage: [(new Date()).Format("yyyy-MM-dd"), (new Date()).Format("yyyy-MM-dd")],
                type: 0,
                area: [],
                search_date: (new Date()).Format("yyyy-MM-dd"),
                mobile: mobile(),
            },
            old: null,
            old_filters: null,
            old_date: null,
            records: [],
            is_first: true,
        }
    },
    computed: {
        ...mapState(['user', 'preset', 'process', 'cache']),
        canAdd() {
            let can = true;
            const level = parseInt(this.user.role_level);
            if (this.preset.app.create_meeting_forbidden) {
                if (level < 3) {
                    can = false;
                }
            }
            return can;
        },
        can_copy() {
            return this.$route.meta ? this.$route.meta.copy : false;
        },
        can_add() {
            return this.$route.meta ? !this.$route.meta.no_add : true
        },
        can_delete() {
            return this.$route.meta ? !this.$route.meta.no_delete : true
        },
        can_edit() {
            return this.$route.meta ? !this.$route.meta.no_edit : true
        }

    },
    methods: {
        ...mapMutations('cache', ['updateCache']),
        reload() {
            let vm = this;
            let obj = Object.assign({}, this.search);
            delete obj.page;
            const diff = JSON.stringify(obj);
            if (this.old !== diff) {
                this.search.page = 1;
                this.old = diff;
            }
            if (this.search.mobile) {
                this.search.date_range = [this.search.search_date, this.search.search_date];
            }
            this.$api(this.model + '.List', this.search, true).then(response => {
                if (response.data.data) {
                    if (vm.is_first) {
                        vm.header = response.data.data.header;
                        vm.extra_filters = response.data.data.extra_filters;
                        let extra = {};
                        if (vm.extra_filters) {
                            for (let key in vm.extra_filters) {
                                extra[key] = vm.extra_filters[key].default;
                            }
                        }
                        vm.$set(vm.search, "extra", extra);
                        let obj = Object.assign({}, this.search);
                        delete obj.page;
                        vm.old = JSON.stringify(obj);
                        this.afterFirstReload();
                    }
                    vm.records = response.data.data.data;
                    vm.page = Object.assign(vm.page, response.data.data.page);
                    vm.is_first = false;
                }
            });
            if (this.use_cache) {
                this.saveToCache();
            }
        },
        handleSuccess() {
            this.edit_mode = false;
            this.reload();
        },
        handleCancel() {
            this.edit_mode = false;
        },
        handleEdit(id) {
            let vm = this;
            this.$api(vm.model + '.Get', {id: id}, true).then(response => {
                vm.single = response.data.data.data || {};
                vm.single_copy = Object.assign({},response.data.data.data);
                vm.fields = response.data.data.fields;
                vm.edit_mode = true;
                Object.keys(vm.fields).forEach(function (key) {
                    let x = vm.fields[key];
                    if (x.type === 'picture' && !vm.single[key]) {
                        vm.single[key] = [];
                    }
                });
            });
        },
        handleCopy(id) {
            let vm = this;
            this.$api(vm.model + '.Get', {id: id}, true).then(response => {
                vm.single = response.data.data.data || {};
                vm.single.copy_id = vm.single.id;
                vm.single.id = 0;
                vm.fields = response.data.data.fields;
                vm.edit_mode = true;
                vm.fields.forEach(x => {
                    if (x.type === 'picture' && !vm.single[x]) {
                        vm.single[x] = []
                    }
                });
            });
        },
        handleReload(data) {
            this.search = data;
            this.reload();
        },
        handleReview(id) {
            let vm = this;
            this.$api(this.model + '.Get', {id: id, review: true}, true).then(res => {
                vm.single = res.data.data;
                this.view_mode = true;
            });
        },
        saveToCache() {
            let key = this.$route.name;
            let data = {};
            data[key] = {
                search: this.search,
                page: this.page
            }
            this.updateCache(data);
        },
        loadFromCache() {
            let key = this.$route.name;
            if (this.cache[key]) {
                let data = this.cache[key];
                if (data) {
                    this.search = Object.assign({}, this.search, data['search']);
                    this.page = Object.assign({}, this.page, data['page']);
                }
            }
            this.reload();
        },
        handleAreaChange(node) {
            console.log(node, 'change');
            this.reload();
        },
        loadArea(level, follow) {
            this.$api('User.LoadArea', {level: level, follow: follow}).then(res => {
                this.areas = res.data.data;
            });
        },
        goDateChangeBack() {
            if (this.search.mobile) {
                let first = this.$dateParse(this.search.search_date);
                first.setDate(first.getDate() - 1);
                this.search.search_date = first.Format('yyyy-MM-dd');
                this.search.date = first.Format('yyyy-MM-dd');
            } else {
                let first = this.$dateParse(this.search.date_range[0]);
                let second = this.$dateParse(this.search.date_range[1]);

                first.setDate(first.getDate() - 1);
                second.setDate(second.getDate() - 1);
                this.search.date_range = [first.Format('yyyy-MM-dd'), second.Format('yyyy-MM-dd')];
                this.search.date = first.Format('yyyy-MM-dd');
            }
            this.reload();
        },
        goDateChangeForward() {
            if (this.search.mobile) {
                let first = this.$dateParse(this.search.search_date);
                first.setDate(first.getDate() + 1);
                this.search.search_date = first.Format('yyyy-MM-dd');
                this.search.date = first.Format('yyyy-MM-dd');
            } else {
                let first = this.$dateParse(this.search.date_range[0]);
                let second = this.$dateParse(this.search.date_range[1]);
                first.setDate(first.getDate() + 1);
                second.setDate(second.getDate() + 1);
                this.search.date_range = [first.Format('yyyy-MM-dd'), second.Format('yyyy-MM-dd')];
                this.search.date = first.Format('yyyy-MM-dd');
            }
            this.reload();
        },
        afterFirstReload(){
        },
        doEditSubmit(single) {
            const vm=this;
            this.$api(this.model + '.Edit', single, true).then(() => {
                    vm.edit_mode = 0;
                    this.$notice.success('记录更新成功!');
                    vm.$emit('success');
                }
            );
        }
    },
    mounted() {
        if (this.use_cache) this.loadFromCache();
        else this.reload();
    },
}
